import { useNavigate, useParams } from 'react-router-dom';
import { useGetSatcFamilyInfoQuery } from '../../store/satc/satcApi';
import Loader from '../../components/shared/loader';
import Tab from '../../components/shared/tabstrip/tab';
import TabStrip from '../../components/shared/tabstrip';
import TabGeneral from './tabGeneral';
import { ReactElement, useEffect } from 'react';
import { APP_URL } from '../../project/defines';
import TabDocTech from './tabDocTech';
import TabVideos from './tabVideos';
import TabFichesDefaut from './tabFichesDefaut';
import TabProcDepannage from './tabProcDepannage';
import TabNoticesRemplacement from './tabNoticesRemplacement';
import TabPVCertifs from './tabPVCertifs';
import TabPhotos from './tabPhotos';
import TabExpertQualite from './tabExpertQualite';
import TabEvolutions from './tabEvolutions';
import TabAccessories from './tabAccessories';
import useTranslation from '../../utils/hooks/useTranslation';
import { getAppUrl } from '../../project/helpers';
import { useAppSelector } from '../../store/hooks';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';

const SatcFamilyDetail: React.FC = () => {
  const { id, tabname } = useParams();
  const navigate = useNavigate();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const { data, isLoading, isFetching } = useGetSatcFamilyInfoQuery({ famille_uuid: id, lang, pays_uuid });
  const loading = isLoading || isFetching;
  const { t } = useTranslation();

  useEffect(() => {
    if (data && tabname) {
      if (isTabDisabled(tabname, data)) {
        navigate(
          getAppUrl(APP_URL.FamilyDetail, {
            routeParams: {
              id: id ?? '',
              tabname: 'general',
            },
          }),
          { replace: true }
        );
      }
    }
  }, [data, tabname, id, navigate]);

  return loading ? (
    <Loader />
  ) : data ? (
    <section>
      <wrapper>
          <TabStrip
            noScroll
          onChange={(index, children) => {
            const tabName = (children[index] as ReactElement)?.props?.tabName;
            navigate(
              getAppUrl(APP_URL.FamilyDetail, {
                routeParams: {
                  id: id ?? '',
                  tabname: tabName,
                },
              })
            );
          }}
          skipSecondaryTabs={false}
          selectedCallback={(children) => {
            const item = children.find((child) => (child as ReactElement)?.props?.tabName === tabname);
            if (item) {
              return children.indexOf(item);
            } else {
              navigate(
                getAppUrl(APP_URL.FamilyDetail, {
                  routeParams: {
                    id: id ?? '',
                    tabname: (children[0] as ReactElement)?.props?.tabName,
                  },
                }),
                { replace: true }
              );
              return 0;
            }
          }}
          id="myTabStrip"
        >
          <Tab
            title={t('satc.detail.tabs.general.title')}
            tabName="general"
            primary
            disabled={isTabDisabled('general', data)}
          >
            <TabGeneral data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.docTech.title')}
            tabName="doc_technique"
            primary
            disabled={isTabDisabled('doc_technique', data)}
          >
            <TabDocTech data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.videoTutos.title')}
            tabName="videos"
            primary
            disabled={isTabDisabled('videos', data)}
          >
            <TabVideos data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.ficheDefaut.title')}
            tabName="fiches_defaut"
            primary
            disabled={isTabDisabled('fiches_defaut', data)}
          >
            <TabFichesDefaut data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.procDepannage.title')}
            tabName="proc_depannage"
            primary
            disabled={isTabDisabled('proc_depannage', data)}
          >
            <TabProcDepannage data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.phototeque.title')}
            tabName="photos"
            primary
            disabled={isTabDisabled('photos', data)}
          >
            <TabPhotos data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.expertQualite.title')}
            tabName="defauts"
            primary
            disabled={isTabDisabled('defauts', data)}
          >
            <TabExpertQualite data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.noticeRemplacement.title')}
            tabName="not_remplacement"
            primary
            disabled={isTabDisabled('not_remplacement', data)}
          >
            <TabNoticesRemplacement data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.accessoires.title')}
            tabName="accessoires"
            primary
            disabled={isTabDisabled('accessoires', data)}
          >
            <TabAccessories data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.evolutions.title')}
            tabName="evolutions"
            primary
            disabled={isTabDisabled('evolutions', data)}
          >
            <TabEvolutions data={data} />
          </Tab>
          <Tab
            title={t('satc.detail.tabs.pvCertifs.title')}
            tabName="pv_certifs"
            primary
            disabled={isTabDisabled('pv_certifs', data)}
          >
            <TabPVCertifs data={data} />
          </Tab>
        </TabStrip>
      </wrapper>
    </section>
  ) : (
    <></>
  );
};

export default SatcFamilyDetail;

const isTabDisabled = (tabname: string, data: any) => {
  switch (tabname) {
    case 'general':
    case 'proc_depannage':
    case 'photos':
    case 'defauts':
    case 'not_remplacement':
    case 'pv_certifs':
      return !Object.keys(data[tabname]).length;
    case 'doc_technique':
      return !Object.keys(data[tabname].PFS_DOC_PRODUIT).length && !Object.keys(data[tabname].PFS_DOC_SAV).length;
    case 'videos':
      return !data[tabname].pfs.length && !data[tabname].docga.length;
    case 'fiches_defaut':
      return !data[tabname].docga.length;
    case 'accessoires':
      return !data[tabname].pfs.length;
    case 'evolutions':
      return !data[tabname].length;
    default:
      return false;
  }
};
