import classNames from 'classnames';
import { RefObject, useEffect, useMemo, useState } from 'react';
import Loader from '../../../components/shared/loader';
import Separator from '../../../components/shared/separator';
import { Text } from '../../../components/shared/text';
import { getClientType, isTypeGarantiesAllowed } from '../../../project/helpers';
import { addNotification } from '../../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Product, ProductWarrantyType, SearchFilterType } from '../../../store/product/productModels';
import { useLazySearchQuery } from '../../../store/search/searchApi';
import { currentCountryUuid } from '../../../store/sideData/siteDataSlice';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';
import useOpener from '../../../utils/hooks/useOpener';
import useTranslation from '../../../utils/hooks/useTranslation';

const ProductSearchAutocomplete: React.FC<{ request: WarrantyRequest; onSelect: (product: Product) => void }> = ({
  request,
  onSelect,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const { open, close, Wrapper, refOpener, refWrapper } = useOpener('bottomForce', true, false, true);
  const [triggerSearch, response] = useLazySearchQuery({});
  const [searchResult, setSearchResult] = useState<any>(null);

  const handleResult = (result: any) => {
    if (result) {
      const { isFetching, isLoading, isSuccess, isError, error, data } = result;
      if (isSuccess && !isFetching && !isLoading) {
        setSearchResult(data);
      } else {
        setSearchResult(null);
        if (isError) {
          dispatch(
            addNotification({
              type: 'error',
              message: t('global.notifications.paniers.title'),
              description: error?.data?.error,
              duration: 5,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    response?.isFetching && open();
  }, [response?.isFetching]); //eslint-disable-line react-hooks/exhaustive-deps

  const onChange = async (e: any) => {
    if (e.target.value.length >= 3) {
      let keywords = e.target.value;
      const result = await triggerSearch(
        {
          type: SearchFilterType.produits,
          keywords,
          lang,
          pays_uuid,
          univers: univers?.uuid!,
          limit: 10,
        },
        true
      );
      handleResult(result);
    } else {
      setSearchResult(null);
    }
  };

  const itemRender = (item: Product, allowSelect: boolean) => {
    return (
      <group
        key={`${item.uuid}-${item.reseau.uuid}`}
        {...(allowSelect ? { 'data-interactive': '' } : { 'data-light': '' })}
        data-radius="5"
        data-align="center"
        data-space="10"
        data-gap="5"
        data-wrap="no"
        onClick={() => {
          if (allowSelect) {
            onSelect(item);
            close();
          }
        }}
        className={classNames('item')}
      >
        <group data-direction="column" data-contain="" data-max-length="fit" data-gap="5">
          <group data-gap="5">
            <text data-weight="700">{`[${item.reference}]`}</text>
            <text>{item.libelle}</text>
          </group>
          <text data-light="" data-wrap="wrap">
            {item.reseau?.nom}
          </text>
        </group>
      </group>
    );
  };

  const productsEnCompte = useMemo(
    () =>
      searchResult?.data.filter((product: Product) => {
        const clientType = getClientType(user, product.reseau?.uuid);
        let warrantyClientEnCompte = clientType ? product.typesGarantie[clientType] : ({} as ProductWarrantyType);
        return (
          product.isEnCompte && product.code && isTypeGarantiesAllowed(warrantyClientEnCompte, request.typedemande)
        );
      }),
    [searchResult, request, user]
  );

  const otherProducts = useMemo(
    () =>
      searchResult?.data.filter((product: Product) => {
        const clientType = getClientType(user, product.reseau?.uuid);
        let warrantyClientEnCompte = clientType ? product.typesGarantie[clientType] : ({} as ProductWarrantyType);
        return !product.isEnCompte || !isTypeGarantiesAllowed(warrantyClientEnCompte, request.typedemande);
      }),
    [searchResult, request, user]
  );

  return (
    <>
      <div className="field" data-length="auto" data-multi-element="">
        <div ref={refOpener as RefObject<HTMLDivElement>} className="form_fields">
          <div className="field_cont">
            <Text data-space="5">garantie.demande.etapes.1.label</Text>
            <Separator vertical />
            <input
              type="text"
              placeholder={t('garantie.demande.etapes.1.placeholder')}
              onChange={onChange}
              onClick={() => {
                searchResult?.data && open();
              }}
            />
            <i></i>
          </div>
        </div>
      </div>
      <Wrapper>
        <group data-space="10" data-background="main-background" data-border="" ref={refWrapper}>
          {response?.isFetching && <Loader />}
          {searchResult?.data?.length === 0 ? (
            <Text light>recherche.noResultats</Text>
          ) : (
            <>
              {productsEnCompte?.length > 0 && (
                <div className="list_view" data-direction="column">
                  <group data-space-horizontal="20" data-gap="10">
                    <Text data-light="">garantie.demande.etapes.1.autocomplete.encompte</Text>
                  </group>
                  {productsEnCompte.map((item: Product) => itemRender(item, true))}
                </div>
              )}
              {otherProducts?.length > 0 && (
                <div className="list_view" data-direction="column">
                  <group data-space-horizontal="20" data-gap="10">
                    <Text data-light="">garantie.demande.etapes.1.autocomplete.autres</Text>
                  </group>
                  {otherProducts.map((item: Product) => itemRender(item, false))}
                </div>
              )}
            </>
          )}
        </group>
      </Wrapper>
    </>
  );
};

export default ProductSearchAutocomplete;
